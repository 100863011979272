import React from 'react';
import { withRouter } from 'react-router-dom';
import 'jquery-ui/ui/widgets/draggable';
import { pr } from './common';

export function jqDlgModal() {
  return $("#dlgModal");
}

class Modal extends React.Component {
  state = { ReactClass: null, props: null, show: false }
  _dialogs_states_cache = {}
  _close = ""
  _handlers = {}

  focusableElements = []

  componentDidMount() {
    window.modal = this.modal;
    this.unlisten = this.props.history.listen(this.historyListen);
    this._dfd = $.Deferred();
    this._promise = this._dfd.promise();
    this._dfd.resolve();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.ReactClass && this.state.ReactClass) {
      return this.waitLazyDialog().then(modalDialog => {
        modalDialog.attr('tabindex', '0');
        modalDialog.attr('role', 'dialog');
        this.focusableElements = $("#dlgModal .modal-dialog")[0].querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ) || [];

        //respect autofocused fields
        if (![...this.focusableElements].find(el => el === document.activeElement)) modalDialog.focus();

        modalDialog.draggable({
          cursor: "grabbing", handle: ".modal-header", cancel: ".close-icon, .modal-header__close-modal"
        });
      })
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
      this.unlisten = undefined;
    }
    window.modal = undefined;
    window.preventModalClose = undefined;
  }

  isLastWasFocused = () => {
    if (!$("#dlgModal .modal-dialog")) return false;

    const notDisabledElements = [...this.focusableElements].filter(el => !el.disabled);
    return document.activeElement === notDisabledElements[notDisabledElements.length - 1];
  }

  //https://stackoverflow.com/a/61511955/10523193
  waitLazyDialog = () => {
    return new Promise(resolve => {
      if ($('#dlgModal .modal-dialog').length) {
        return resolve($('#dlgModal .modal-dialog'));
      }

      const observer = new MutationObserver(mutations => {
        if ($('#dlgModal .modal-dialog').length) {
          observer.disconnect();
          resolve($('#dlgModal .modal-dialog'));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  historyListen = (location, action) => {
    action && this.hideModal();
  }

  showModal = () => {
    document.body.classList.add("modal-open");
    document.addEventListener('mousedown', this.mouse_down);
    document.addEventListener('mouseup', this.mouse_up);
    document.addEventListener("keydown", this.handleKey, false);
    document.addEventListener("click", this.handleClickOutside);
    this.setState({show: true});
  }

  hideModal = () => {
    if (this.preventClose()) return;

    if (window.forceCloseModalCallback && typeof window.forceCloseModalCallback === 'function') {
      window.forceCloseModalCallback();
      return;
    }
    document.removeEventListener('mousedown', this.mouse_down);
    document.removeEventListener('mouseup', this.mouse_up);
    document.removeEventListener("keydown", this.handleEscKey, false);
    document.removeEventListener("click", this.handleClickOutside);
    this.onClose();
    this.closeModal();
  }

  mouse_down = (e) => window.clickStartedInModal = $(e.target).is('.modal-dialog *');

  mouse_up = (e) => {
    window.preventModalClose = (!$(e.target).is('.modal-dialog *') && window.clickStartedInModal) ? true : false;
    return true;
  }

  preventClose = e => {
    if(window.preventModalClose){
      window.preventModalClose = false;
      return true;
    }
    return false;
  }

  handleClickOutside = e => {
    if (e.target.id == 'dlgModal') this.hideModal();
  }

  modal = (ReactClass, props, addclass) => {
    if (!jqDlgModal()) {
      alertify.error("Modal dialogs not loaded");
      return;
    }
    if (typeof ReactClass == "string") {
      switch (ReactClass) {
        case "submit":
          this._close = "submit";
          this.hideModal();
          break;

        case "cancel":
        case "hide":
          this._close = "cancel";
          this.hideModal();
          break;

        case "clear_cache":
          this._dialogs_states_cache = {};
          break;

        case "get_promise":
          return this._promise;
          break;

        default:
          this.showModal();
      }
      return;
    }
    if (ReactClass && this.state.ReactClass && ReactClass != this.state.ReactClass ) this.onClose();
    this.setState({ ReactClass, props, addclass });
    this._dfd = $.Deferred();
    this._promise = this._dfd.promise();
    this._close = "";

    this.showModal();
  }

  regHandlers = (onCancel, onSubmit, cacheHandlers) => this._handlers = {onCancel, onSubmit, cacheHandlers}

  onClose = () => {
    this._dfd.resolve();
    if (window.preventModalClose) return false;
    if (!this._close) {
      let ch = this._handlers.cacheHandlers,
          ch_key = ch && ch.getCacheKey && ch.getCacheKey(),
          ch_state = ch && ch.getCacheState && ch.getCacheState();
      if (ch && ch_key && ch_state) {
        this._dialogs_states_cache[ch_key] = ch_state;
      }
      if (typeof this._handlers.onCancel == "function") {
        this._handlers.onCancel();
      }
    } else {
      if (this._close == "submit" && typeof this._handlers.onSubmit == "function") this._handlers.onSubmit();
      this._dialogs_states_cache = {};
    }
    this._close = "";
    this.regHandlers();
  }

  closeModal = () => {
    this._close = "close";
    document.body.classList.remove("modal-open");
    this.setState({ ReactClass: null, props: null, addclass: "", show: false });
    jqDlgModal().trigger('hidden.bs.modal');
  }

  handleKey = e => {
    if (e.key === "Escape") {
      this.hideModal();
    } else if (e.key === "Tab") {
      //NOTE: this.focusableElements will be reevaled each time inside this.isLastWasFocused method
      //NOTE2: this checkings are really called before focus is really changed by bubbled "'tab' pressing" event. So document.activeElement has previous value of focus
      if (this.isLastWasFocused()) {
        pr(e)
        //focus on first focusable element again when focus is out of modal
        if (this.focusableElements[0]) this.focusableElements[0].focus();
      } else if (!this.focusableElements.length) { //if no focusable elements inside modal then just restrict focus to exit modal
        pr(e)
        $("#dlgModal .modal-dialog").focus();
      }
    }
  }

  render() {
    let { ReactClass, addclass, props } = this.state;
    return (
      <div id="dlgModal" className={classNames("modal custom-modal", {"modal-open": this.state.show}, addclass)}>
        {ReactClass
        ? <ReactClass {...props} regHandlers={this.regHandlers} stateCache={this._dialogs_states_cache}/>
        : null
        }
      </div>
    );
  }
}

export default withRouter(Modal);
