import React from 'react';
import { Link } from 'react-router-dom';
import { publicConstants } from './public-constants';

export default function Footer(props) {
  const LinkWrp = _props => {
    if (props.page404) return <a href={_props.to} className={_props.className || ''}>{_props.children}</a>
    return <Link to={_props.to} className={_props.className || ''}>{_props.children}</Link>
  }

  return (
    <footer className='footer'>
      <div className="footer-wrapper">
        <div className='__col'>
          <span className='__col-title'>Features</span>
          <LinkWrp to="/features-gcs" className='__col-href'>For General Contractors</LinkWrp>
          <LinkWrp to="/features-subs" className='__col-href'>For Subcontractors</LinkWrp>
          <LinkWrp to="/features-suppliers" className='__col-href'>For Suppliers</LinkWrp>
          <LinkWrp to="/features-small-business" className='__col-href'>For Small Businesses</LinkWrp>
        </div>
        <div className='__col'>
          <span className='__col-title'>Pricing</span>
          <LinkWrp to="/pricing/gcs" className='__col-href'>For General Contractors</LinkWrp>
          <LinkWrp to="/pricing/subs" className='__col-href'>For Subcontractors</LinkWrp>
          <LinkWrp to="/pricing/small-business" className='__col-href'>For Small Businesses</LinkWrp>
        </div>

        <div className='__col'>
          <span className='__col-title'>Resources</span>
          <LinkWrp to="/quick-start" className='__col-href'>Quick Start Guide</LinkWrp>
          <LinkWrp to="/tutorials" className='__col-href'>Tutorials and Tips</LinkWrp>
          <LinkWrp to="/submittal-templates" className='__col-href'>Templates</LinkWrp>
        </div>
        <div className='__col'>
          <span className='__col-title'>Company</span>
          <LinkWrp to="/aboutus" className='__col-href'>About</LinkWrp>
          <LinkWrp to="/termsofuse" className='__col-href'>Terms of Use (Updated {publicConstants.dateTermsUpd})</LinkWrp>
          <LinkWrp to="/dataformat" className='__col-href'>Data Format</LinkWrp>
          <LinkWrp to="/privacypolicy" className='__col-href'>Privacy Policy</LinkWrp>
          <a href="https://buildsite.com/info/company/careers.html" className='__col-href' target='_blank' >
            Careers<i className="subicon _external-icon" style={{fontSize: '16px'}} />
          </a>
        </div>

        <div className='__col'>
    <span className='__col-title'>
      <LinkWrp to="/contact" className='__col-href titled'>Contact</LinkWrp>
    </span>
        </div>
        <div className='__col logo_info'>
          <div>
            <LinkWrp to='/home' className='__logo'/>
          </div>
        </div>
        <div className='__col comp_info'>
          {publicConstants.phone}<br/>
          Copyright 2017-{(new Date()).getFullYear()}<br/>
          BuildSite LLC<br/>
          All Rights Reserved.<br/>
        </div>
      </div>
    </footer>
  );
}
